<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->

            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Nastavenia štýlov obrázkov</h3>
                    <h2 class="">{{fieldData['site_name'].value}}</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 col-md-9 col-lg-12  order-xl-1">
                    <a-card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12" v-for="(field, index) in fieldData">
                                            <a-input alternative="" v-if="field.type === '0'"
                                                        :label="field.title"
                                                        input-classes="form-control-alternative"
                                                        v-model="fieldData[field.slug].value"
                                            />
                                            <image-upload :data="fieldData[field.slug].value" :title="field.title" :dat="field.slug" v-if="field.type === '3'"  @changed="(e) => onChildChange(e,field.slug)"></image-upload>

                                            <div class="mb-4" v-if="field.type === '4'" >
                                                <label class="form-control-label">{{field.title}}</label>
                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                                          v-model="fieldData[field.slug].value"
                                                          :placeholder="field.title"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <a class="btn btn-secondary" :href="'/translate/' + this.id" v-if="fieldData['language'] === 'sk'" >Preložiť</a>   <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {dataService} from "../_services";
    import Image from "./content/Image";

    import FormItem from 'ant-design-vue'
    import Vue from "vue"
    Vue.use(FormItem);
    export default {
        name: 'variables',
        components: {'image-upload' : Image},
        data() {
            return {
                url: getURL(),
                fieldData: {},
                colorsto: JSON.parse(localStorage.colors),
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
            this.form.getFieldDecorator('keys', {initialValue: [], preserve: true});
        },
        methods: {
            onChildChange(value,slug) {
                this.fieldData[slug].value = JSON.stringify(value);
            },

            handleSubmit() {
                dataService.axiosPost(this.$options.name, this.fieldData).then(results => {
                    if (results.data.success) {
                        this.$message.success('Nastavenia boli uložené');
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
        },
        mounted() {
            dataService.axiosFetch(this.$options.name).then(results => {
                this.fieldData = results
            });
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }

    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }


</style>
